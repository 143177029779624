define("viewmodel", ['devicetype'], function(devicetype) {

    function performLoad(name, req, onload, errorCallback) {
        req([name], function(val) {
            onload(val);
        }, errorCallback);
    }

    var viewModelLoader = {
        load: function(name, req, onload, config) {

        	var deviceSpecificViewModelName = name + "." + devicetype.getDeviceType();

            if (req.specified(deviceSpecificViewModelName)) {
                performLoad(deviceSpecificViewModelName, req, onload);
            } else if (req.specified(name)) {
                performLoad(name, req, onload);
            } else {
                performLoad(deviceSpecificViewModelName, req, onload, function(error) {
                    if (error.requireType === 'scripterror' || error.requireType === 'nodefine') {
                        performLoad(name, req, onload);
                    } else {
                        throw(error);
                    }
                });
            }
        }
    };

    return viewModelLoader;

});
