define("inherit", function() {

    function getInheritanceObject(superObj) {
        if (superObj) {
            superObj.__extend = getInheritanceMethod(superObj);
        }
        return superObj;
    }

    function getInheritanceMethod(superObj) {
        return function(subObj) {
            inherit(subObj, superObj);
            return subObj;
        };
    }

    function inherit(subObj, superObj) {
        function F() {}
        F.prototype = superObj.prototype;
        var f = new F();

        subObj.prototype = f;
        subObj.prototype.constructor = subObj;
    }

    var inheritFrom = {
        load: function(name, req, onload, config) {
            req([name], function(val) {
                onload(getInheritanceObject(val));
            });
        }
    };

    return inheritFrom;
});
